<template>
    <div>
        <kanban-board :stages="stages" :blocks="blocks">

        </kanban-board>     
    </div>
</template>

<script>
    export default {
      data() {
        return {
          stages: ['on-hold', 'in-progress', 'needs-review', 'approved'],
          blocks: [
            {
              id: 1,
              status: 'on-hold',
              title: 'Test',
              description: 'Test desc',
            },
          ],
        }
      }
    }
</script>
